import $ from 'jquery';
import i18next from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import jqueryI18next from 'jquery-i18next';
import ScrollMagic from 'scrollmagic';
//import 'scrollmagic/scrollmagic/minified/plugins/debug.addIndicators.min';
import 'scrollmagic/scrollmagic/minified/plugins/animation.gsap.min';
import 'scrollmagic/scrollmagic/minified/plugins/jquery.ScrollMagic.min';

i18next.use(LanguageDetector);
i18next.use(XHR);
i18next.init({
  debug: false,
  supportedLngs: ['it', 'rm', 'de', 'fr'],
  fallbackLng: "fr",
  backend: {
    loadPath: '{{ns}}s_{{lng}}.json'
  }
}, function () {
  jqueryI18next.init(i18next, $);
  $(document).localize();
  localizeFields();
});

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', window.innerHeight + 'px');
};

$(function () {
  window.addEventListener('resize', appHeight);
  appHeight();
  langMenu();
  navMenu();

  // init ScrollMagic Controller
  var controller = new ScrollMagic.Controller({container: "#content-wrapper"});
  setupScene1(controller);

  // ### Section 2: Yes yes yes ###
  setupScene2(controller);

  // ### Section 3: Activists ###
  setupScene3(controller);

  // ### Section 4: Crowd ###
  setupScene4(controller);

  // ### Section 5: Anna ###
  setupScene5(controller);

  // ### Section 6: Anna in phone ###
  setupScene6(controller);
});

function langMenu() {
  $('.lang-menu').change(function () {
    const locale = $(this).find("option:selected").attr('value');

    i18next.changeLanguage(locale, (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      // t('key'); // -> same as i18next.t
      $(".lang-menu").val(locale);
      $('body').localize();
      localizeFields(locale);
    });
  });
}

function createStartLink(lang) {
  const urlParams = window.location.search.replace('?', '');

  const buttonStartEnd = $('#anna-in-phone-button-start');
  buttonStartEnd.attr('href', buttonStartEnd.attr('href').split('?')[0]);
  buttonStartEnd.attr('href', buttonStartEnd.attr('href') + '?l=' + lang + (urlParams.length > 0 ? '&' + urlParams : ''));

  const directStart = $('#scroll-tuto-info a');
  directStart.attr('href', buttonStartEnd.attr('href'));

  const buttonStartStart = $('#logo-button-start');
  buttonStartStart.attr('href', buttonStartEnd.attr('href'));
}

function localizeFields() {
  $(".lang-menu").val(i18next.language);
  $('.branding-logo').attr('src', '/logo-' + i18next.language + '.png');
  $('#logo-image').attr('src', '/anna-logo-' + i18next.language + '.png');
  createStartLink(i18next.language);
}

function navMenu() {
  $('.open-menu').click(() => {
    $('.menu').toggleClass('is-open');
  });

  $('.close-menu').click(() => {
    $('.menu').removeClass('is-open');
  });
}

function setupScene1(controller) {
  new ScrollMagic.Scene({triggerElement: "#trigger-logo"})
    .setClassToggle("#scroll-tuto-info", 'is-hidden')
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-logo", duration: 150})
    .setTween("#logo-image", 1, {top: "-400px", ease: Power4.easeInOut})
    //.addIndicators({name: "logo up"})
    .addTo(controller);
}

function setupScene2(controller) {

  new ScrollMagic.Scene({triggerElement: "#trigger-date", duration: 800})
    .setPin("#date")
    //.addIndicators({name: "Pin date & fade date"})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-date", duration: 800})
    .setTween("#date", {opacity: "0", scale: 1.5, ease: Linear.easeNone})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-date", duration: 1300, offset: 100})
    .setTween("#color-spot-br", 1, {y: "-100%", x: "50%", scale: 2})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-date", duration: 1500, offset: 100})
    .setTween("#color-spot-tl", 1, {x: "-100%", y: "-20%", scale: 3})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-date", duration: 1200, offset: 100})
    .setTween("#color-spot-bl", 1, {x: "-100%", y: "30%"})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-bg-yes", duration: 750})
    .setTween("#bg-ul-1", {y: "-100%", ease: Linear.easeNone})
    //.addIndicators({name: "Tween bg"})
    .addTo(controller);
  new ScrollMagic.Scene({triggerElement: "#trigger-bg-yes", duration: 750})
    .setTween("#bg-ol-1", {y: "-100%", ease: Linear.easeNone})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-bg-yes", duration: 750})
    .setTween("#bg-ol-2", {y: "-180%", ease: Linear.easeNone})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-bg-yes", duration: 500})
    .setTween("#bg-ol-3", {y: "-500%", ease: Linear.easeNone})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-show-papers", duration: 600})
    .setTween("#papers", {rotation: "720", scale: 1, opacity: 1, ease: Linear.easeNone})
    //.addIndicators({name: "Tween papers"})
    .addTo(controller);

  const timelineMountainLeft = new TimelineMax();
  timelineMountainLeft
    .add(TweenMax.to("#votation-date-mountains-left", 1, {y: 0, ease: Back.easeOut.config(1.2)}))
    .add(TweenMax.to("#votation-date-mountains-left", 1, {x: "-100%", ease: Back.easeIn.config(1.1)}), "+=0.2");

  new ScrollMagic.Scene({triggerElement: "#trigger-show-votation-mountains", duration: 1000})
    .setTween(timelineMountainLeft)
    //.addIndicators({name: "show mountains"})
    .addTo(controller);

  const timelineMountainRight = new TimelineMax();
  timelineMountainRight
    .add(TweenMax.to("#votation-date-mountains-right", 1, {y: 0, ease: Back.easeOut.config(1.2)}))
    .add(TweenMax.to("#votation-date-mountains-right", 1, {x: "100%", ease: Back.easeIn.config(1.1)}), "+=0.2");

  new ScrollMagic.Scene({triggerElement: "#trigger-show-votation-mountains", duration: 1000, offset: 100})
    .setTween(timelineMountainRight)
    //.addIndicators({name: "show mountains"})
    .addTo(controller);

  // Pin the bgs
  new ScrollMagic.Scene({triggerElement: "#trigger-pin-bg", duration: 50})
    .setPin("#bg-wrapper")
    //.addIndicators({name: "Pin 1"})
    .addTo(controller);

  // Poussière le journal
  new ScrollMagic.Scene({triggerElement: "#trigger-throw-paper", duration: 500})
    .setTween("#papers", {y: "-300%", scale: 2, ease: Linear.easeNone})
    //.addIndicators({name: "Throw paper"})
    .addTo(controller);
}

function setupScene3(controller) {
  new ScrollMagic.Scene({triggerElement: "#trigger-pin-activists", duration: 4000})
    .setPin("#pin-activists")
    //.addIndicators({name: "Pin activists"})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-show-activists-stains", duration: 700, offset: 100})
    .setTween("#activists-crowd-bg", {y: 0, ease: Back.easeOut.config(1.2)})
    //.addIndicators({name: "Show crowd"})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-show-activists-stains", duration: 700})
    .setTween("#ac-sc-1", {y: 0, ease: Back.easeOut.config(1.5)})
    //.addIndicators({name: "Show stains"})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-show-activists-stains", duration: 700, offset: 50})
    .setTween("#ac-sc-2", {y: 0, ease: Back.easeOut.config(1.5)})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-show-activists-stains", duration: 700, offset: 30})
    .setTween("#ac-sc-3", {y: 0, ease: Back.easeOut.config(1.5)})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-zoom-activists", duration: 400})
    .setTween("#activists-bg", {scale: 2, opacity: 0, ease: Linear.easeNone})
    //.addIndicators({name: "Zoom activists"})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-fade-activists-text", duration: 400})
    .setTween("#activists-text", {opacity: 0, ease: Linear.easeNone})
    //.addIndicators({name: "Fade text"})
    .addTo(controller);
}

function setupScene4(controller) {
  new ScrollMagic.Scene({triggerElement: "#trigger-pin-crowd", duration: 3700})
    .setPin("#pin-crowd")
    //.addIndicators({name: "Pin crowd"})
    .addTo(controller);

  const timelineZoom = new TimelineMax();
  timelineZoom
    .add(TweenMax.to("#crowd-text", 1, {opacity: 0, ease: Linear.easeNone}, 0))
    .add(TweenMax.to("#crowd-bgs", 1, {scale: 5, opacity: 0, y: '30%', ease: Linear.easeNone}, 0));

  new ScrollMagic.Scene({triggerElement: "#trigger-zoom-crowd", duration: 600})
    .setTween(timelineZoom)
    //.addIndicators({name: "Zoom crowd"})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-translate-crowd", duration: 400})
    .setTween("#cu-2", {y: '0', ease: Linear.easeNone})
    //.addIndicators({name: "Paralax crowd"})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-translate-crowd", duration: 500})
    .setTween("#cu-1", {y: '0', ease: Linear.easeNone})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-translate-crowd", duration: 300})
    .setTween("#co-2", {y: '0', ease: Linear.easeNone})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-translate-crowd", duration: 400})
    .setTween("#co-1", {y: '0', ease: Linear.easeNone})
    .addTo(controller);
}

function setupScene5(controller) {
  new ScrollMagic.Scene({triggerElement: "#trigger-pin-anna", duration: 3000})
    .setPin("#pin-anna")
    //.addIndicators({name: "Pin Anna"})
    .addTo(controller);

  const timelineShowAnna = new TimelineMax();
  timelineShowAnna
    .add(TweenMax.to("#anna-bgs", 1, {opacity: 1, scale: 1, ease: Linear.easeNone}))
    .add(TweenMax.to("#anna-text", 1, {opacity: 1, ease: Linear.easeNone}));

  new ScrollMagic.Scene({triggerElement: "#trigger-pin-anna", duration: 400})
    .setTween(timelineShowAnna)
    //.addIndicators({name: "Show anna"})
    .addTo(controller);

  const timelineShowNS = new TimelineMax();
  timelineShowNS
    .add(TweenMax.to("#anna-bg-shout", 1, {left: "2rem", ease: Back.easeOut.config(1.2)}))
    .add(TweenMax.to("#anna-bg-shoutbox", 1, {right: "2rem", ease: Back.easeOut.config(1.2)}));

  new ScrollMagic.Scene({triggerElement: "#trigger-anna-show-sn", duration: 500})
    .setTween(timelineShowNS)
    //.addIndicators({name: "Show NS"})
    .addTo(controller);


  const timelineStrainL = new TimelineMax();
  timelineStrainL
    .add(TweenMax.to("#anna-stain-l", 1, {y: 0, ease: Power2.easeOut}))
    .add(TweenMax.to("#anna-stain-l", 1, {scale: 1.1, ease: Power2.easeOut}))
    .add(TweenMax.to("#anna-stain-l", 1, {x: "-100%", ease: Back.easeIn.config(1.4)}), "+=0.2");

  new ScrollMagic.Scene({triggerElement: "#trigger-anna-show-stains", duration: 2000, offset: 100})
    .setTween(timelineStrainL)
    .addTo(controller);

  const timelineStrainR = new TimelineMax();
  timelineStrainR
    .add(TweenMax.to("#anna-stain-r", 1, {y: 0, ease: Power2.easeOut}))
    .add(TweenMax.to("#anna-stain-r", 1, {scale: 1.4, ease: Power2.easeOut}))
    .add(TweenMax.to("#anna-stain-r", 1, {x: "100%", ease: Back.easeIn.config(1.4)}), "+=0.2");

  new ScrollMagic.Scene({triggerElement: "#trigger-anna-show-stains", duration: 2000})
    .setTween(timelineStrainR)
    .addTo(controller);


  const timelineShowPhone = new TimelineMax();
  timelineShowPhone
    .add(TweenMax.to("#anna-text", 1, {opacity: 0, ease: Linear.easeNone}))
    .add(TweenMax.to("#anna-phone", 1, {top: '30vh', ease: Linear.easeNone}));

  new ScrollMagic.Scene({triggerElement: "#trigger-show-anna-phone", duration: 500})
    .setTween(timelineShowPhone)
    //.addIndicators({name: "Show phone"})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-scale-down-anna", duration: 300})
    .setTween("#anna-bgs", {scale: 1.5, opacity: 0, y: '-10%', ease: Linear.easeNone})
    //.addIndicators({name: "Scale down anna"})
    .addTo(controller);
}

function setupScene6(controller) {
  new ScrollMagic.Scene({triggerElement: "#trigger-pin-anna-in-phone"})
    .setPin("#pin-anna-in-phone")
    //.addIndicators({name: "Pin Anna in phone"})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-fade-anna-in-phone", duration: 300})
    .setTween("#anna-in-phone-bgs", {opacity: 1, ease: Linear.easeNone})
    //.addIndicators({name: "Fade in"})
    .addTo(controller);

  const timelineChangeText = new TimelineMax();
  timelineChangeText
    .add(TweenMax.to("#anna-in-phone-text-1", 1, {opacity: 0, ease: Linear.easeNone}))
    .add(TweenMax.to("#anna-in-phone-bg", 1, {y: "200%", ease: Linear.easeNone}))
    .add(TweenMax.to("#anna-in-phone-text-2", 1, {opacity: 1, ease: Linear.easeNone}));

  new ScrollMagic.Scene({triggerElement: "#trigger-move-out-anna-in-phone", duration: 800})
    .setTween(timelineChangeText)
    //.addIndicators({name: "Fade in"})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-show-start-button", duration: 200})
    .setTween("#anna-in-phone-button-start", {opacity: 1})
    //.addIndicators({name: "Start button"})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-show-start-button"})
    .setClassToggle("#anna-in-phone-button-start", "is-clickable")
    //.addIndicators({name: "Start button"})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-move-out-anna-in-phone", duration: 800})
    .setTween("#color-spot-br", {y: "0%", x: "0%", scale: 1})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-move-out-anna-in-phone", duration: 1200})
    .setTween("#color-spot-tl", {x: "0%", y: "0%", scale: 1})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-move-out-anna-in-phone", duration: 700, offset: 200})
    .setTween("#color-spot-bl", {x: "0%", y: "0%", scale: 1})
    .addTo(controller);

  new ScrollMagic.Scene({triggerElement: "#trigger-end-scroll", duration: 200})
    //.addIndicators({name: "End scroll"})
    .setTween("#scroll-down", {opacity: 0})
    .addTo(controller);
}
